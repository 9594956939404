<template>
	<Default :loading="loading">
		<div class="relative w-full h-full relative md:flex">
			<DealAction v-if="(deals.length || deal) && action" :action="action" :bulk="user.permissions?.includes('deal.action.bulk')" :deals="deals" :deal="deal" :project="options.project" @abort="onAbort" @success="onSuccess" />

			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full w-full">
				<Banner type="success" :open="!!success" class="mb-0">
					<p>
						{{ success }}
					</p>
				</Banner>
				<div class="grow p-3 bg-gray-100">
					<div class="md:flex md:items-center">
						<h1 class="text-2xl text-gray-800 font-bold mb-2 md:mb-0 flex-1">Oportunidades</h1>
					</div>
					<div class="mt-2">
						<Accordion title="Filtros" class="bg-white">
							<div class="grid md:grid-cols-2 xl:grid-cols-4 gap-2 my-2">
								<input v-if="isFinance" v-model="options['contract.customer.identifier']" class="form-input focus:border-gray-300" type="text" @input="resetSkip" placeholder="CEDULA: xxxxxxx" />

								<select v-show="origins.length > 1" v-if="origins" v-model="options.origin" class="form-select">
									<option v-for="origin in origins" :key="origin._id" :value="origin._id">
										{{ origin.label }}
									</option>
								</select>

								<input v-if="!isFinance" name="cups" id="cups" v-model="options['contract.cups']" class="form-input focus:border-gray-300 flex-1" type="text" @input="resetSkip" placeholder="CUPS: XXXXX" />

								<select v-if="projects" name="project" v-model="options.project" class="form-select" @change="resetSkip">
									<option :value="undefined" disabled="true">Selecciona proyecto</option>
									<option v-for="project in projects" :key="project._id" :value="project.name">
										{{ project.label }}
									</option>
								</select>

								<Multiselect v-model="options.status" track-by="type" label="label" placeholder="Seleccionar estados" :hideSelected="true" :multiple="true" :options="statuses" :showNoResults="true" :closeOnSelect="false" :preserveSearch="true" :clearOnSelect="false" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para eliminar">
									<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
									<template #noOptions>No se han encontrado estados. </template>
								</Multiselect>

								<Multiselect v-if="users && user.usersCount" name="usersFilter" v-model="options.user" track-by="_id" :custom-label="u => `${u.name} ${u.surname}`" placeholder="Seleccionar usuarios" :multiple="true" :options="users" :hideSelected="true" :showNoResults="true" :closeOnSelect="false" :clearOnSelect="false" :preserveSearch="true" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para eliminar">
									<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
									<template #noOptions>No se han encontrado usuarios. </template>
								</Multiselect>

								<div v-if="isFinance">
									<input class="form-input w-full" placeholder="Deuda desde" v-model="amountToPayFrom" type="number" :min="0" :max="100000" />
									<small class="w-full p-1">Deuda desde</small>
								</div>
								<div v-if="isFinance">
									<input class="form-input w-full" placeholder="Deuda hasta" v-model="amountToPayTo" type="number" :min="0" :max="100000" />
									<small class="w-full p-1">Deuda hasta</small>
								</div>
								<Multiselect v-if="!isFinance && user.permissions?.includes('filter.rate')" name="rate" id="rate" v-model="selectedRates" track-by="_id" label="label" placeholder="Seleccionar tarifas" :hideSelected="true" :closeOnSelect="false" :multiple="true" :options="rates" @select="onSelectRate" @remove="onSelectRate" :showNoResults="true" :preserveSearch="true" :clearOnSelect="false" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
									<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
									<template #noOptions>No se han encontrado tarifas. </template>
								</Multiselect>

								<div v-if="isFinance">
									<input class="w-full form-input" type="datetime-local" name="from" v-model="options.from" />
									<small class="w-full p-1">Fecha reporte desde</small>
								</div>
								<div v-if="isFinance">
									<input class="w-full form-input" type="datetime-local" name="to" v-model="options.to" />
									<small class="w-full p-1">Fecha reporte hasta</small>
								</div>

								<input v-if="isFinance" v-model="options.q" class="form-input focus:border-gray-300" type="text" placeholder="Nombre del cliente" @input="resetSkip" />
							</div>
						</Accordion>
					</div>

					<div class="bg-white rounded-sm border border-gray-200 relative mt-2">
						<div class="p-1">
							<header class="gap-3 font-semibold text-slate-800 flex">
								<div class="flex flex-1 items-center gap-3">
									<div v-if="deals.length" class="flex flex-wrap gap-3 font-semibold text-slate-800 ml-2">
										<p>Aplicar a ({{ deals.length }}/{{ total }}):</p>
										<div class="flex sm:flex-row flex-wrap sm:justify-start gap-3">
											<Button v-for="action of Object.values(actions)" :key="action._id" @click="onAction(action)" size="xs">{{ action.label }}</Button>
											<Button color="danger" @click="unselectAllDeals" size="xs">Quitar selección</Button>
										</div>
									</div>
								</div>
								<div class="gap-1 text-sm flex flex-1 items-center flex-row-reverse">
									<Button @click="download" v-if="user.permissions.includes('deal.download')" color="secondary" class="border-0 ml-2 mr-2" aria-haspopup="true" icon="ri-arrow-down-s-line" :disabled="!items.length">
										<icon name="ri-download-line" class="opacity-70 w-4 h-4" />
										<span class="ml-2 hidden sm:block"> Descargar </span>
									</Button>
									<select v-model="options.limit" class="ml-2 form-select w-20" @change="resetSkip">
										<option :value="5">5</option>
										<option :value="10">10</option>
										<option :value="20">20</option>
										<option :value="50">50</option>
										<option :value="100">100</option>
										<option :value="150">150</option>
									</select>
									Mostrar:
								</div>
							</header>
						</div>
						<!-- Table -->
						<div class="overflow-x-auto" v-if="!loading && items.length">
							<table class="table-auto w-full">
								<!-- Table header -->
								<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
									<tr>
										<th v-if="user.permissions.includes('deal.action.bulk')" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<icon name="ri-check-line" />
										</th>
										<th v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.customer.identifier')">
													<p class="uppercase mr-1">Cédula</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.customer.identifier'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.customer.name')">
													<p class="uppercase mr-1">Cliente</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.customer.name'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.amountToPay')">
													<p class="uppercase mr-1">Deuda</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.amountToPay'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.customer.lastContactAt')">
													<p class="uppercase mr-1">Último contacto</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.customer.lastContactAt'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center">
													<p class="uppercase mr-1">Último reporte</p>
												</button>
											</div>
										</th>

										<th v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.cups')">
													<p class="uppercase mr-1">Cups</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.cups'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.rate')">
													<p class="uppercase mr-1">Tarifa</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.rate'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('statusLabel')">
													<p class="uppercase mr-1">Estado</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'statusLabel'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('createdAt')">
													<p class="uppercase mr-1">Bloqueado</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'createdAt'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">Usuario</div>
										</th>
										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">Acciones</div>
										</th>
									</tr>
								</thead>
								<!-- Table body -->
								<tbody class="text-sm divide-y divide-gray-200">
									<tr v-for="(item, i) in items" :key="item._id" class="even:bg-gray-100 hover:bg-gray-200">
										<td v-if="user.permissions?.includes('deal.action.bulk')" class="px-2 text-center">
											<label :for="item._id" class="block w-full h-full py-2">
												<input :id="item._id" type="checkbox" class="form-checkbox bg-green-200 hover:bg-green-300 disabled:bg-red-200 cursor-pointer disabled:cursor-not-allowed" name="actions" v-model="deals" :value="item" :disabled="!item.actions?.length" />
											</label>
										</td>
										<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-medium text-light-blue-500">
												<router-link
													:to="{
														name: 'go.dealDashboard',
														query: {
															_id: item._id,
															project: options.project
														}
													}"
												>
													{{ item.contract.customer?.identifier }}
												</router-link>
											</div>
										</td>

										<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-medium text-light-blue-500">
												<router-link
													:to="{
														name: 'go.dealDashboard',
														query: {
															_id: item._id,
															project: options.project
														}
													}"
												>
													{{ item.contract.customer.name }} {{ item.contract.customer.lastname }}
												</router-link>
											</div>
										</td>

										<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<router-link
												:to="{
													name: 'go.dealDashboard',
													query: {
														_id: item._id,
														project: options.project
													}
												}"
											>
												<div v-if="item.contract.amountToPay" class="font-medium text-light-blue-500">{{ parseCurrency(item.contract?.amountToPay || 0, 'en-US', 'USD') }}</div>
											</router-link>
										</td>

										<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-medium">
												{{ item.contract.customer.lastContactAt ? dayjs(item.contract.customer.lastContactAt).format('D MMM YYYY H:mm') : '-' }}
											</div>
										</td>

										<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-medium">
												{{ item.contract.customer.lastReportAt ? dayjs(item.contract.customer.lastReportAt).format('D MMM YYYY H:mm') : '-' }}
											</div>
										</td>

										<td v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div v-if="item.contract" class="font-medium text-light-blue-500">
												<router-link
													:to="{
														name: 'go.dealDashboard',
														query: {
															_id: item._id,
															project: options.project
														}
													}"
												>
													{{ item.contract.cups }}
												</router-link>
											</div>
										</td>
										<td v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-medium text-light-blue-500">
												<span
													class="text-xs mr-1 font-semibold text-white px-1.5 rounded"
													:class="{
														'bg-blue-600': item.contract.rate == 2,
														'bg-green-500': item.contract.rate == 3,
														'bg-green-800': item.contract.rate == 3.1,
														'bg-purple-500': item.contract.rate == 6.1,
														'bg-purple-800': item.contract.rate == 6.2,
														'bg-red-600': item.contract.rate == 6.3,
														'bg-red-800': item.contract.rate == 6.4
													}"
												>
													{{ parseRate(item.contract.rate) }}
												</span>
											</div>
										</td>
										<td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div v-if="item.statusLabel" class="font-medium text-light-blue-500">
												{{ item.statusLabel }}
											</div>
										</td>
										<td v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-medium">
												{{ dayjs(item.createdAt).format('D MMM H:mm') }}
											</div>
										</td>
										<td v-if="item.user" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{{ item.user.name }} {{ item.user.surname }}</td>
										<td class="px-2 first:pl-5 last:pr-5 py-3 flex gap-2">
											<Button size="sm" color="tertiary" @click=";(showHistory = true), (deal = item)">
												<icon name="ri-information-line" />
											</Button>
											<Button
												v-for="(action, idx) of item.actions"
												size="sm"
												:title="action.label"
												@click="
													() => {
														deal = item
														onAction(action)
													}
												"
												color="warning"
												:key="idx"
												:disabled="!!deals.length"
											>
												{{ action.label }}
											</Button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="mt-8 mb-24" v-if="!loading && items.length">
						<Pagination v-show="total > options.limit" :results="items.length" :skip="options.skip" :limit="options.limit" :total="total" @page="updatePage" />
					</div>

					<div class="w-full flex justify-center p-4" v-if="!loading && !items.length">
						<div class="text-center py-6">
							<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-red-300 to-red-100 mb-4">
								<icon name="ri-information-line" scale="2" />
							</div>
							<h2 class="text-2xl text-gray-800 font-bold mb-2">No se han encontrado oportunidades.</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
		<DealHistory v-if="showHistory" @abort=";(showHistory = false), (deal = null)" :project="options.project" :deal="deal" />
	</Default>
</template>

<script setup lang="ts">
import DealAction from '@/apps/go/partials/deal/DealAction.vue'
import { ValidationError } from '@/errors/ValidationError'
import { dayjs } from '@/services/dayjs'
import { user } from '@/user'
import { ComputedRef, Ref, computed, onMounted, reactive, ref, watch } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRoute, useRouter } from 'vue-router'
import Accordion from '../../components/Accordion.vue'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import Pagination from '../../components/Pagination.vue'
import { Project } from '../../interfaces/Project'
import { get } from '../../services/api'
import { parseCurrency } from '../../utils/parseCurrency'
import { parseRate } from '../../utils/parseRate'
import Default from '../Default.vue'
import { DealAction as DealActionInterface } from '../../interfaces/DealAction'
import { Deal } from '../../interfaces/Deal'
import { queryRegExpToStr } from '../../utils/queryRegExpToStr'
import { strToRegExp } from '../../utils/strToRegExp'
import DealHistory from './DealHistory.vue'
import { Origin } from '../../interfaces/Origin'

const route = useRoute()
const router = useRouter()
const isFinance = import.meta.env.VITE_PRODUCT == 'finance'
const showHistory = ref(false)

const origins: Ref<Origin[]> = ref([])

const options: Record<string, any> = reactive({
	skip: route.query.skip ? parseInt(<string>route.query.skip) : 0,
	sort: route.query.sort || 'name',
	limit: route.query.limit ? parseInt(<string>route.query.limit) : 20,
	user: route.query.user ? route.query.user : undefined,
	status: route.query.status ? route.query.status : undefined,
	project: route.query.project ? route.query.project : user.value.projects[0],
	...((route.query.origin && { origin: route.query.origin }) || (origins.value?.length && { origin: origins.value[0]._id })),
	'contract.cups': route.query['contract.cups'] ? queryRegExpToStr(route.query['contract.cups'].toString()) : undefined,
	'contract.customer.identifier': route.query['contract.customer.identifier'] ? queryRegExpToStr(route.query['contract.customer.identifier'].toString()) : undefined,
	q: route.query.q ? queryRegExpToStr(route.query.q.toString()) : undefined
})

const loading = ref(false)
const loadingDownload = ref(false)
const items = ref([])
const users = ref([])
const usersToReassignDeals = ref([])
const statuses = ref([])
const total = ref(0)
const success = ref(null)
const error = ref({})
const errors = ref([])
const action = ref(null)
const deal = ref<Deal>(null)
const deals = ref<Deal[]>([])
const selectedRates = ref([])
const projects: Ref<Project[]> = ref([])
const sortBy = reactive({ field: (<string>options.sort || '')?.split('-')[0], type: options.sort?.includes('-') ? '-' : '' })
const amountToPayFrom = ref(0)
const amountToPayTo = ref(99999)

const handleSort = column => {
	if (column != sortBy.field) {
		sortBy.field = column
		sortBy.type = ''
	} else {
		sortBy.type = sortBy.type === '-' ? '' : '-'
	}
	options.sort = `${sortBy.type}${sortBy.field}`
}

const onAction = async (toAction?: DealActionInterface) => {
	errors.value = []
	action.value = toAction
}

const rates = [
	{ _id: 2, label: '2.0TD' },
	{ _id: 3, label: '3.0TD' },
	{ _id: 3.1, label: '3.1TD' },
	{ _id: 6.1, label: '6.1TD' },
	{ _id: 6.2, label: '6.2TD' },
	{ _id: 6.3, label: '6.3TD' },
	{ _id: 6.4, label: '6.4TD' }
]

const onSelectRate = () => {
	const selected = selectedRates.value.map(rate => rate._id)
	if (selected.length) {
		options['contract.rate'] = selected.join(',')
	} else {
		delete options['contract.rate']
	}
}

const fetchOrigins = async () => {
	try {
		const { data } = await get({ name: 'origin' })
		origins.value = data
		options.origin = (!!(<string>route.query.origin) && origins.value.find(origin => route.query.origin === origin._id))?._id || origins.value[0]._id
	} catch (err) {
		error.value = err
	}
}

const fetchProjects = async () => {
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'project', query: { sort: 'name', projection: 'label,name' } })
		projects.value = data.filter((project: Project) => user.value?.projects?.includes(project.name)) || []
	} catch (err) {
		error.value = err
	}
}

const query = computed(() => {
	return {
		...route.query,
		...options,
		project: options.project,
		origin: options.origin,
		q: options.q ? strToRegExp(options.q) : undefined,
		from: options.from ? options.from : undefined,
		to: options.to ? options.to : undefined,
		user: options.user && options.user.length ? options.user.map(u => u._id).join(',') : undefined,
		status: options.status && options.status.length ? options.status.map(s => s.type).join(',') : undefined,
		'contract.customer.identifier': options['contract.customer.identifier'] ? strToRegExp(options['contract.customer.identifier']) : undefined,
		'contract.cups': options['contract.cups'] ? strToRegExp(options['contract.cups']) : undefined
	}
})

const search = async () => {
	if (options.project && options.origin) {
		loading.value = true
		error.value = {}
		items.value = []
		try {
			const { project, ...restRouteQuery } = route.query

			const apiQuery = { ...restRouteQuery, projection: 'history,contract.amountToPay,contract.customer.identifier,contract.customer.lastContactAt,contract.customer.lastReportAt,contract.customer.name,contract.customer.lastname,contract.cups,contract.rate,statusLabel,user,actions,createdAt' }

			if (isFinance) {
				apiQuery['contract.amountToPay>'] = amountToPayFrom.value
				apiQuery['contract.amountToPay<'] = amountToPayTo.value
			}

			const { data, pagination } = await get({ name: 'deal', query: apiQuery, params: { project: options.project } })

			data.map(item => (item.user = users.value.find(user => user._id == item.user)))
			items.value = data
			total.value = pagination.total
		} catch (err) {
			console.log(err)

			if (error instanceof ValidationError) {
				errors.value = err.errors || []
			} else {
				error.value = err
			}
		}
		loading.value = false
	}
}

const unselectAllDeals = () => {
	deals.value = []
}

const searchUsers = async () => {
	error.value = <any>{}
	try {
		users.value = []
		const { data } = await get({ name: 'user', query: { limit: '5999', sort: 'name,surname', projection: 'name,surname' } })
		users.value = data
		options.user = options.user ? users.value.filter(u => options.user.includes(u._id)) : []
		usersToReassignDeals.value = data
	} catch (err) {
		if (err instanceof ValidationError) {
			errors.value = err.errors || []
		} else {
			error.value = err
		}
	}
}
const searchStatuses = async () => {
	error.value = <any>{}
	try {
		statuses.value = []
		const apiQuery = { 'config.isStatus': 'true', 'config.status': null, projection: 'config.status' }
		const { data } = await get({ name: 'action', query: apiQuery })

		statuses.value = data.map((item: Record<string, any>) => item.config.status)
		options.status = options.status ? statuses.value.filter(s => options.status.includes(s.type)) : []
	} catch (err) {
		if (err instanceof ValidationError) {
			errors.value = err.errors || []
		} else {
			error.value = err
		}
	}
}

watch([options, amountToPayFrom, amountToPayTo], async () => {
	deals.value = []
	await router.replace({ query: query.value })
	if (options.origin && options.project && statuses.value.length && users.value.length && projects.value.length && origins.value.length) {
		search()
	}
})

onMounted(async () => {
	await Promise.all([fetchOrigins(), fetchProjects(), searchUsers(), searchStatuses()])
	search()
	selectedRates.value = [...(route.query['contract.rate']?.length ? rates.filter(({ _id }) => route.query['contract.rate']?.includes(_id.toString())) : [])]
})

const onAbort = () => {
	action.value = null
	success.value = null
}

const onSuccess = () => {
	action.value = null
	deal.value = null
	deals.value = []
	success.value = 'Actualizado corréctamente'
	search()
}

const updatePage = (page: Ref) => {
	options.skip = page.value == -1 ? 0 : (page.value - 1) * options.limit
}

const resetSkip = () => {
	options.skip = 0
}

const actions: ComputedRef<Record<string, DealActionInterface>> = computed(() => {
	return deals.value.reduce((acc, item) => {
		item.actions.forEach(action => {
			acc[action._id] = action
		})
		return acc
	}, {})
})

const download = async () => {
	loadingDownload.value = true
	try {
		const { project, ...restRouteQuery } = route.query

		const apiQuery = { ...restRouteQuery, limit: 1 }

		if (isFinance) {
			apiQuery['contract.amountToPay>'] = amountToPayFrom.value
			apiQuery['contract.amountToPay<'] = amountToPayTo.value
		}

		const { data } = await get({ name: 'deal.download', params: { project }, query: apiQuery })
		let base64Uri = `data:@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
		const xlsxBlob = await fetch(base64Uri).then(res => res.blob())
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(xlsxBlob)
		link.download = `deals_${dayjs().format('DD-MM-YYYY')}.xlsx`
		link.click()
		link.remove()
	} catch (error) {
		alert('Error al descargar oportunidades.')
	}
	loadingDownload.value = false
}
</script>

<template>
	<Default>
		<div class="w-full min-h-full relative flex">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 w-full">
				<div class="sticky top-14 bg-white z-10" v-show="current || showList">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<Button color="tertiary" @click="stopNavigation">
							<icon name="ri-arrow-left-line" class="opacity-70 w-5 h-5 mr-1" />
							Mapa
						</Button>
					</div>
					<div v-if="current" class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<Button color="secondary" @click="prev" :disabled="navigationIndex == 0 || loading">
							<icon name="ri-arrow-left-line" class="mr-1" />
							Anterior
						</Button>
						<span class="bg-gray-500 rounded-full text-white px-2 text-sm">{{ navigationIndex + 1 }} / {{ navigationList.length }}</span>
						<Button color="secondary" @click="next" :disabled="navigationIndex == navigationList.length - 1 || loading || loadingMap">
							<icon name="ri-arrow-right-line" class="mr-1" />
							Siguiente
						</Button>
					</div>
				</div>
				<div class="grow relative">
					<div class="absolute top-0 right-0 z-10" v-if="!current && !showList">
						<div class="flex items-start justify-end p-3 gap-2">
							<Accordion title="Localización" class="bg-white z-10 shadow-xl" v-if="mapAdminPermission">
								<div class="flex flex-col gap-2 mt-1">
									<div class="flex flex-row items-center gap-2">
										<input v-model="disableGeolocation" name="disableGeolocation" id="disableGeolocation" class="form-input w-4 h-6" type="checkbox" />
										<label for="disableGeolocation" class="block">Ignorar Ubicación</label>
									</div>
									<div><label class="block w-full" for="lat">Latitud</label><input name="lat" id="lat" class="form-input w-full" type="number" required="true" step="0.0001" v-model="latForm" /></div>
									<div><label class="block w-full" for="lng">Longitud</label><input name="lng" id="lng" class="form-input w-full" type="number" required="true" v-model="lngForm" step="0.0001" /></div>
									<Button @click="updateLocationForm">Actualizar</Button>
								</div>
							</Accordion>

							<Accordion title="Opciones" class="bg-white z-10 shadow-xl">
								<div class="flex flex-col gap-2 mt-1">
									<select v-show="origins.length > 1" v-model="options.origin" class="form-select">
										<option v-for="origin in origins" :key="origin._id" :value="origin._id">
											{{ origin.label }}
										</option>
									</select>
									<select v-model="options.mode" class="form-select">
										<option value="explore">Explorar</option>
										<option value="portfolio">Mi cartera</option>
										<option v-if="user.usersCount > 1" value="deals">Carteras</option>
									</select>
									<select v-if="user" v-model="options.project" class="form-select">
										<option :value="undefined" disabled="true">Seleccionar proyecto</option>
										<option v-for="project in projects" :key="project._id" :value="project.name">
											{{ project.label }}
										</option>
									</select>
									<div v-if="isFinance">
										<label class="block w-full">Deuda desde:</label>
										<div class="flex items-center mb-2">
											<input class="form-input w-full" v-model="amountToPayFrom" type="number" :min="0" :max="100000" />
										</div>
										<label class="block w-full">Deuda hasta:</label>
										<div class="flex items-center mb-2">
											<input class="form-input w-full" v-model="amountToPayTo" type="number" :min="0" :max="100000" />
										</div>
									</div>
									<Multiselect v-if="!isFinance && user.permissions?.includes('filter.rate')" name="rate" id="rate" v-model="selectedRates" track-by="_id" label="label" placeholder="Tarifas" :hideSelected="true" :closeOnSelect="false" :multiple="true" :options="rates" @select="onSelectRate" @remove="onSelectRate" :showNoResults="true" :preserveSearch="true" :clearOnSelect="false" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
										<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
										<template #noOptions>No se han encontrado tarifas. </template>
									</Multiselect>
									<div>
										<label class="block w-full">Distancia en metros:</label>
										<div class="relative flex items-center max-w-[12rem]">
											<button @click="options.distance > 25 ? (options.distance -= 25) : (options.distance = 1)" type="button" id="decrement-button" data-input-counter-decrement="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
												<svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
													<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
												</svg>
											</button>

											<input type="number" id="quantity-input" data-input-counter aria-describedby="helper-text-explanation" class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" :max="user.config.maxDistance" @input="onDistanceChange" :value="options.distance" />
											<button @click="options.distance < user.config.maxDistance - 25 ? (options.distance += 25) : (options.distance = user.config.maxDistance)" type="button" id="increment-button" data-input-counter-increment="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
												<svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
													<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
												</svg>
											</button>
										</div>
									</div>
									<div>
										<label class="block w-full">Límite:</label>
										<div class="relative flex items-center max-w-[12rem]">
											<button @click="options.limit > 25 ? (options.limit -= 25) : (options.limit = 1)" type="button" id="decrement-button" data-input-counter-decrement="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
												<svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
													<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
												</svg>
											</button>
											<input type="number" id="quantity-input" data-input-counter aria-describedby="helper-text-explanation" class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" :max="user.config.maxLimit" @input="onLimitChange" :value="options.limit" />
											<button @click="options.limit < user.config.maxLimit - 25 ? (options.limit += 25) : (options.limit = user.config.maxLimit)" type="button" id="increment-button" data-input-counter-increment="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
												<svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
													<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
												</svg>
											</button>
										</div>
									</div>
									<div v-if="options.mode == 'deals' && isFinance" class="flex flex-col mt-2">
										<input class="w-full form-input" type="datetime-local" name="from" v-model="from" />
										<small class="p-1">Fecha reporte desde</small>
									</div>
									<div v-if="options.mode == 'deals' && isFinance" class="flex flex-col">
										<input class="w-full form-input" type="datetime-local" name="to" v-model="to" />
										<small class="p-1">Fecha reporte hasta</small>
									</div>
								</div>
							</Accordion>

							<button class="bg-white rounded-full text-gray-600 p-2 hover:text-white hover:bg-indigo-600 border border-slate-200 shadow-xl" :class="{ 'border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed': !items.length }" @click="toggleShowList" :disabled="!items.length">
								<icon name="ri-list-check" class="opacity-70 w-6 h-5" />
							</button>
						</div>
					</div>

					<MapWithUserLocation v-if="!current && !showList" :markers="markers" :distance="parseInt(options.distance)" :center="options.lat && options.lng ? { lat: options.lat, lng: options.lng } : null" @center="onLocation" @ready="loadingMap = false" @error="loadingMap = false" :minZoom="4" :maxZoom="18" :zoom="options.zoom" :timeout="timeout" :disableGeolocation="disableGeolocation" :zoomControl="true" :userMarkerAnimate="userMarkerAnimate" @zoom="onZoom" @click="updateLocationManually" :dragging="mapAdminPermission" />

					<div class="p-3" v-if="navigationList.length && !current && !loading && showList">
						<div class="bg-white rounded-sm border border-gray-200 mt-2">
							<div>
								<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3">
									<icon name="ri-hand-coin-line" class="opacity-70 mr-1" />
									<span>Oportunidades ({{ navigationList.length }})</span>
								</div>
								<div class="overflow-x-auto">
									<table class="table-auto w-full">
										<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
											<tr>
												<th class="px-2 last:pr-5 py-2 whitespace-nowrap">&nbsp;</th>
												<th v-if="isFinance" class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">Deuda</div>
												</th>
												<th v-if="!isFinance" class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">Tarifa</div>
												</th>
												<th class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">Distancia</div>
												</th>
												<th v-if="!isFinance" class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">CUPS</div>
												</th>
												<th v-if="isFinance" class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">Deuda Inicial</div>
												</th>
												<th v-if="!isFinance" class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">Descripción</div>
												</th>
												<th class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">Estado</div>
												</th>
												<th v-if="options.mode == 'deals'" class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">Agente</div>
												</th>
											</tr>
										</thead>
										<tbody class="text-sm divide-y divide-gray-200">
											<tr v-bind:key="item._id" v-for="(item, index) in navigationList" @click="startNavigationFromIndex(index)" class="even:bg-sky-100 hover:bg-sky-200 cursor-pointer">
												<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
													<icon v-if="item.isDeal && !item.finishedAt" name="ri-lock-line" class="opacity-90 w-5 h-5 mr-1" />
													<icon v-if="item.isDeal && item.finishedAt" name="ri-folder-lock-line" class="opacity-90 w-5 h-5 mr-1" />
													<icon v-if="!item.isDeal" name="ri-lock-unlock-line" class="opacity-20 w-5 h-5 mr-1" />
												</td>
												<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-medium text-light-blue-500">
														{{ parseCurrency(item.contract.amount || 0, 'en-US', 'USD') }}
													</div>
												</td>
												<td v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-medium text-light-blue-500">
														<span
															class="text-xs mr-1 font-semibold text-white px-1.5 rounded"
															:class="{
																'bg-blue-600': item.contract.rate == 2,
																'bg-green-500': item.contract.rate == 3,
																'bg-green-800': item.contract.rate == 3.1,
																'bg-purple-500': item.contract.rate == 6.1,
																'bg-purple-800': item.contract.rate == 6.2,
																'bg-red-600': item.contract.rate == 6.3,
																'bg-red-800': item.contract.rate == 6.4
															}"
														>
															{{ parseRate(item.contract.rate) }}
														</span>
													</div>
												</td>

												<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-medium text-light-blue-500">{{ item.distance.toFixed(1) }} m</div>
												</td>

												<td v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-medium text-light-blue-500">
														{{ item.contract.cups }}
													</div>
												</td>

												<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-medium text-red-500">
														{{ parseCurrency(item.contract.amountToPay || 0, 'en-US', 'USD') }}
													</div>
												</td>

												<td v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-medium text-light-blue-500">
														{{ item.contract.address.raw }}
													</div>
												</td>

												<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-medium text-light-blue-500">
														{{ item.isDeal ? item.statusLabel : 'Pdt. Bloquear' }}
													</div>
												</td>

												<th v-if="options.mode == 'deals'" class="px-2 last:pr-5 py-2 whitespace-nowrap">
													<div class="font-semibold text-left">{{ item.user.name }} {{ item.user.surname }}</div>
												</th>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<Deal v-if="current && current.isDeal" :distance="current.distance" :_id="current._id" :project="options.project" @fetchEnd="onFetch" @fetchStart="fetchStart" :hideLoading="true" />
					<Lead v-if="current && !current.isDeal" :distance="current.distance" :_id="current._id" :project="options.project" @lock="onFetch" :lockOnFetch="true" />
				</div>
			</div>
		</div>
	</Default>
</template>

<style>
.marker svg,
.marker svg polygon,
.marker svg path,
.marker svg text {
	filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 0.3));
}

.marker svg:hover {
	opacity: 0.7;
}
</style>

<script setup lang="ts">
import router from '@/router/router'
import { dayjs } from '@/services/dayjs'
import { user } from '@/user'
import { marker } from 'leaflet'
import { Ref, onBeforeMount, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRoute } from 'vue-router'
import Accordion from '../components/Accordion.vue'
import Button from '../components/Button.vue'
import MapWithUserLocation from '../components/MapWithUserLocation.vue'
import { Project } from '../interfaces/Project'
import { calculateDistance } from '../utils/calculateDistance'
import { parseCurrency } from '../utils/parseCurrency'
import { getMarkerGroupIcon, getMarkerUserIcon } from './../icons/getMarkerGroupIcon'
import { get } from './../services/api'
import Default from './Default.vue'
import Deal from './deal/Deal.vue'
import Lead from './deal/Lead.vue'
import { parseRate } from '../utils/parseRate'
import { Deal as DealInterface } from '../interfaces/Deal'
import { Lead as LeadInterface } from '../interfaces/Lead'
import { Origin } from '../interfaces/Origin'

const product = import.meta.env.VITE_PRODUCT
const debug = import.meta.env.VITE_DEBUG
const isFinance = product == 'finance'
const loading = ref(true)
const disableGeolocation = ref(false)
const error = ref({})
const loadingMap = ref(true)
const userMarkerAnimate = ref(false)
const lastRequest: any = { distance: 0, date: null, lat: null, lng: null }
const distance = ref(0)
const timeRequest = ref(0)
const current: Ref<(DealInterface & { isDeal: boolean; distance: number }) | (LeadInterface & { isDeal: boolean; distance: number }) | null> = ref(null)
const showList = ref(false)
const items: Ref<any[]> = ref([])
const groups: Ref<any[]> = ref([])
const markers: Ref<any[]> = ref([])
const users: Ref<any[]> = ref([])
const navigationList: Ref<any[]> = ref([])
const navigationIndex = ref(0)
const projects: Ref<Project[]> = ref([])
const selectedRates: Ref<any[]> = ref([])
const origins: Ref<Origin[]> = ref([])
const amountToPayFrom = ref(0)
const amountToPayTo = ref(99999)
const mapAdminPermission = user.value.permissions.includes('map.admin')
const route = useRoute()
const timeout = 8000

const latForm = ref(null)
const lngForm = ref(null)

const from = ref(null)
const to = ref(null)

const onLimitChange = e => {
	const val = e.target.value
	if (val > user.value.config.maxLimit) {
		options.limit = user.value.config.maxLimit
	} else if (val < 1) {
		options.limit = 1
	} else {
		options.limit = val
	}
	e.target.value = options.limit
}

const onDistanceChange = e => {
	const val = e.target.value
	if (val > user.value.config.maxDistance) {
		options.distance = user.value.config.maxDistance
	} else if (val < 1) {
		options.distance = 1
	} else {
		options.distance = val
	}
	e.target.value = options.limit
}

const mapFilterOptionsString = localStorage.getItem('mapFilterOptions')
const options: Record<string, any> = reactive(
	mapFilterOptionsString
		? JSON.parse(mapFilterOptionsString)
		: {
				zoom: 16,
				limit: user.value.config.limit,
				distance: user.value.config.distance,
				mode: 'explore'
		  }
)

const toggleShowList = () => {
	showList.value = !showList.value
}

const onZoom = zoom => {
	options.zoom = zoom
}

const updateLocationManually = loc => {
	if (mapAdminPermission) {
		options.lat = loc.lat
		options.lng = loc.lng
	}
}

const updateLocationForm = loc => {
	options.lat = latForm.value
	options.lng = lngForm.value
}

const fetchStart = () => {
	loading.value = true
}

const onFetch = deal => {
	loading.value = false
	current.value = deal
	if (current.value) {
		current.value.isDeal = true
		current.value.user = users.value.find(user => user._id == deal.user) || deal.user
		const navigationItem = navigationList.value[navigationIndex.value]
		const navigationListTmp = [...navigationList.value]
		navigationListTmp[navigationIndex.value] = { ...navigationItem, ...current.value }
		navigationList.value = navigationListTmp
	}
}

const rates = [
	{ _id: 2, label: '2.0TD' },
	{ _id: 3, label: '3.0TD' },
	{ _id: 3.1, label: '3.1TD' },
	{ _id: 6.1, label: '6.1TD' },
	{ _id: 6.2, label: '6.2TD' },
	{ _id: 6.3, label: '6.3TD' },
	{ _id: 6.4, label: '6.4TD' }
]
const onSelectRate = () => {
	const selected = selectedRates.value.map(rate => rate._id)
	if (selected.length) {
		options['contract.rate'] = selected.join(',')
	} else {
		delete options['contract.rate']
	}
}

const onLocation = async ({ lat, lng }) => {
	options.lat = lat
	options.lng = lng
	// await fetch()
}

const config = {
	minTimeRequest: 3000,
	minDistanceRequest: 50
}

const fetch = async (force?: boolean) => {
	if (options.origin && options.project && options.lat && options.lng) {
		if (lastRequest.distance) {
			lastRequest.distance = calculateDistance(lastRequest.lat, lastRequest.lng, options.lat, options.lng)
			timeRequest.value = dayjs().diff(lastRequest.date)
		}

		if (force || !lastRequest.date || (distance.value > config.minDistanceRequest && timeRequest.value > config.minTimeRequest)) {
			lastRequest.lat = options.lat
			lastRequest.lng = options.lng
			lastRequest.date = dayjs()
			userMarkerAnimate.value = true
			items.value = []
			markers.value = []
			loading.value = true
			try {
				const { project, zoom, mode, ...restOptions } = options
				let index = isFinance ? 'amount' : 'rate'
				let apiQuery = { ...restOptions, sort: 'distance' }
				if (isFinance) {
					apiQuery['contract.amountToPay>'] = amountToPayFrom.value
					apiQuery['contract.amountToPay<'] = amountToPayTo.value
				}

				if (mode == 'deals') {
					await fetchUsers()
				} else {
					users.value = [user]
				}

				let data = []

				if (mode != 'deals') {
					let leadsResponse = await get({ name: 'lead', params: { project }, query: apiQuery })
					data = leadsResponse.data
				}
				const name = ['explore', 'portfolio'].includes(options.mode) ? 'me.deal' : 'deal'
				apiQuery['projection'] = `user,finishedAt,statusLabel,loc,distance,contract.address.raw,contract.cups,contract.amount,contract.amountToPay,contract.${index}`
				const res = await get({ name, params: { project }, query: apiQuery })
				data = data
					.concat(res.data)
					.map((item: any) => ({
						...item,
						isDeal: !!item.statusLabel,
						user: users.value.find(user => user._id == item.user) || item.user
					}))
					.sort((a, b) => {
						return a.distance - b.distance
					})

				items.value = data
				navigationList.value = items.value
				groups.value = getGroupsByCoords(data, index)
				refreshMarkers()
			} catch (error) {
				console.error(error)
			}
			userMarkerAnimate.value = false
			loading.value = false
		}
	} else {
		items.value = []
		groups.value = []
		markers.value = []
	}
}

const getGroupsByCoords = (items, index) => {
	// create groups using coords
	return Object.values(
		items.reduce((acc, curr) => {
			const key = `${curr.loc.coordinates[0]},${curr.loc.coordinates[1]}`
			if (!acc[key]) {
				acc[key] = {
					lng: curr.loc.coordinates[0],
					lat: curr.loc.coordinates[1],
					distance: curr.distance,
					meta: {
						index: curr.contract[index],
						deals: curr.isDeal ? [curr] : [],
						users: curr.isDeal ? [curr.user] : []
					},
					items: [curr]
				}
			} else {
				acc[key].meta.index = Math.max(acc[key].meta.index, curr.contract[index])
				acc[key].items.push(curr)
				if (curr.isDeal) {
					acc[key].meta.deals.push(curr)
					if (!acc[key].meta.users.find(user => user._id == curr.user._id)) {
						acc[key].meta.users.push(curr.user)
					}
				}
			}
			return acc
		}, {})
	)
}

const refreshMarkers = () => {
	const tmp = []
	groups.value
		.filter(group => (['portfolio', 'deals'].includes(options.mode) && group.meta.deals.length) || (['explore'].includes(options.mode) && (options.mode != 'explore' || (options.mode == 'explore' && group.items.length > group.meta.deals.length))))
		.forEach(group => {
			let icon = options.mode == 'deals' ? getMarkerUserIcon(group) : getMarkerGroupIcon(group)
			tmp.push(
				marker([group.lat, group.lng], { icon }).on('click', e => {
					if (debug) {
						console.debug('DEBUG', group, icon)
					}
					startNavigationByLocation(group.lat, group.lng)
				})
			)
		})
	markers.value = tmp
}

const refreshNavigationList = (lat, lng) => {
	navigationList.value = [...items.value]
		.filter(item => (['portfolio', 'deals'].includes(options.mode) && item.isDeal) || ['explore'].includes(options.mode))
		.map(item => {
			return { ...item, navigationDistance: calculateDistance(lat, lng, item.loc.coordinates[1], item.loc.coordinates[0]) }
		})
		.sort((a, b) => {
			return a.navigationDistance - b.navigationDistance
		})
}

const prev = () => {
	current.value = null
	navigationIndex.value--
	current.value = navigationList.value[navigationIndex.value]
}

const next = () => {
	current.value = null
	navigationIndex.value++
	current.value = navigationList.value[navigationIndex.value]
}

const startNavigationByLocation = (lat: number, lng: number) => {
	navigationIndex.value = 0
	refreshNavigationList(lat, lng)
	current.value = navigationList.value[0]
}

const startNavigationFromIndex = (index: number = 0) => {
	navigationIndex.value = index
	current.value = navigationList.value[index]
}

const stopNavigation = async () => {
	current.value = null
	showList.value = false
}

const fetchProjects = async () => {
	try {
		const { data } = await get({ name: 'project', query: { sort: 'name', projection: 'name,label' } })
		projects.value = data.filter((project: Project) => user.value?.projects?.includes(project.name)) || []
		if (!options.project) {
			options.project = (!!(<string>route.query.project) && projects.value.find(project => route.query.project === project.name))?.name || projects.value[0].name
		}
	} catch (err) {
		error.value = err
	}
}

const fetchOrigins = async () => {
	try {
		const { data } = await get({ name: 'origin' })
		origins.value = data
		if (!options.origin) {
			options.origin = (!!(<string>route.query.origin) && origins.value.find(origin => route.query.origin === origin._id))?._id || origins.value[0]._id
		}
	} catch (err) {
		error.value = err
	}
}

const fetchUsers = async () => {
	try {
		users.value = []
		const apiQuery = { limit: '5999', projection: 'name,surname,config.color' }
		const { data } = await get({ name: 'user', query: apiQuery })
		users.value = data
	} catch (err) {
		alert(err)
	}
}

// ONLY FOR DEV
onBeforeMount(() => {
	if (!isFinance) {
		delete options.amountToPay
	}
})

onMounted(async () => {
	loading.value = false
	await fetchOrigins()
	await fetchProjects()
	await fetch()
	if (options['contract.rate']) {
		selectedRates.value = options['contract.rate'].split(',').map(rateId => rates.find(rate => rate._id == rateId))
	}
})

onUnmounted(() => {
	items.value = []
	groups.value = []
	markers.value = []
})

watch(options, () => {
	localStorage.setItem('mapFilterOptions', JSON.stringify(options))
	const { distance, limit, project, lat, lng, origin, ...query } = options
	latForm.value = lat
	lngForm.value = lng
	router.replace({ query })
})

watch([() => options.origin, () => options.project, () => options.limit, () => options.distance, () => options.lat, () => options.lng, () => options.rate], () => {
	fetch(true)
})

watch([amountToPayFrom, amountToPayTo, selectedRates], () => {
	fetch(true)
})

watch([from, to], async () => {
	groups.value.forEach(group => (group.meta.reported = false))

	if (from.value || to.value) {
		const { project, zoom, mode, ...restOptions } = options
		let queryParams = { ...restOptions, sort: 'distance' }
		const { data } = await get({ name: 'deal', params: { project }, query: { projection: '_id,loc', ...queryParams, ...(from.value && { from: from.value }), ...(to.value && { to: to.value }) } })
		data.forEach(deal => {
			const group = groups.value.find(group => group.lat == deal.loc.coordinates[1] && group.lng == deal.loc.coordinates[0])
			if (group) {
				group.meta.reported = true
			}
		})
		refreshMarkers()
	}
})

watch([() => options.mode], ([newMode], [oldMode]) => {
	if ([newMode, oldMode].includes('deals')) {
		fetch(true)
	} else {
		refreshMarkers()
	}
})
</script>

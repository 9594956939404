<template>
	<Default :loading="loading">
		<div class="w-full h-full relative md:flex">
			<div class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0 h-full">
				<!-- Container -->
				<div class="grow p-3 bg-gray-100">
					<!-- Page header -->
					<div class="md:flex md:items-center mb-3">
						<!-- Left: Title -->
						<h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-2 md:mb-0 flex-1">Cartera</h1>
						<!-- Add new -->
					</div>

					<!-- More actions -->
					<Accordion title="Filtros" class="bg-white">
						<div class="grid items-start gap-2 mt-3" v-if="isFinance">
							<div class="grid md:grid-cols-2 gap-2">
								<input v-model="options['contract.customer.identifier']" class="form-input focus:border-gray-300" type="text" placeholder="CEDULA: xxxxxxx" @input="onChangeSearchInput" />

								<select name="project" v-model="options.project" class="form-select">
									<option :value="undefined" disabled="true">Selecciona proyecto</option>
									<option v-for="project in projects" :key="project._id" :value="project.name">
										{{ project.label }}
									</option>
								</select>
								<div class="flex items-center mb-2">
									<input class="form-input w-full" v-model="amountToPayFrom" type="number" :min="0" :max="100000" />
								</div>
								<div class="flex items-center mb-2">
									<input class="form-input w-full" v-model="amountToPayTo" type="number" :min="0" :max="100000" />
								</div>
							</div>

							<Multiselect v-model="selectedStatuses" track-by="type" label="label" placeholder="Seleccionar estados" :hideSelected="true" :closeOnSelect="false" :multiple="true" :options="statuses" :showNoResults="true" :preserveSearch="true" :clearOnSelect="false" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
								<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
								<template #noOptions>No se han encontrado estados. </template>
							</Multiselect>
						</div>
						<div class="grid items-start gap-2 mt-3" v-else>
							<div class="grid md:grid-cols-2 gap-2">
								<select v-show="origins.length > 1" v-model="options.origin" class="form-select col-span-2">
									<option v-for="origin in origins" :key="origin._id" :value="origin._id">
										{{ origin.label }}
									</option>
								</select>

								<input v-model="options['contract.cups']" class="form-input focus:border-gray-300" type="text" placeholder="CUPS: XXXXX" @input="onChangeSearchInput" />

								<select name="project" v-model="options.project" class="form-select">
									<option :value="undefined" disabled="true">Selecciona proyecto</option>
									<option v-for="project in projects" :key="project._id" :value="project.name">
										{{ project.label }}
									</option>
								</select>
							</div>
							<Multiselect v-model="selectedStatuses" track-by="type" label="label" placeholder="Seleccionar estados" :hideSelected="true" :closeOnSelect="false" :multiple="true" :options="statuses" :showNoResults="true" :preserveSearch="true" :clearOnSelect="false" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
								<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
								<template #noOptions>No se han encontrado estados. </template>
							</Multiselect>
							<Multiselect v-if="user.permissions?.includes('filter.rate')" name="rate" id="rate" v-model="selectedRates" track-by="_id" label="label" placeholder="Seleccionar tarifas" :hideSelected="true" :closeOnSelect="false" :multiple="true" :options="rates" @select="onSelectRate" @remove="onSelectRate" :showNoResults="true" :preserveSearch="true" :clearOnSelect="false" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover">
								<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
								<template #noOptions>No se han encontrado tarifas. </template>
							</Multiselect>
						</div>
					</Accordion>

					<!-- Table -->
					<div class="bg-white rounded-sm border border-gray-200 relative mt-3">
						<div class="md:flex md:items-center py-2 px-3">
							<header class="mb-2 md:mb-0 flex-1 font-semibold text-slate-800">
								Resultados:
								<span class="text-slate-400">{{ total }}</span>
							</header>
							<div class="gap-2 flex items-center">
								Mostrar:
								<select v-model="options.limit" class="ml-2 form-select flex-1 md:flex-auto" @change="onChangeLimit">
									<option :value="5">5</option>
									<option :value="10">10</option>
									<option :value="20">20</option>
									<option :value="50">50</option>
									<option :value="100">100</option>
									<option :value="150">150</option>
								</select>
							</div>
						</div>

						<!-- Table -->
						<div class="overflow-x-auto" v-if="!loading && items.length">
							<table class="table-auto w-full">
								<!-- Table header -->
								<thead class="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-b border-t border-gray-200">
									<tr>
										<th v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.amount')">
													<p class="uppercase mr-1">Monto</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.amount'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.rate')">
													<p class="uppercase mr-1">Tarifa</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.rate'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.cups')">
													<p class="uppercase mr-1">Cups</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.cups'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.amountToPay')">
													<p class="uppercase mr-1">Monto PDT</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.amountToPay'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.amountToPay')">
													<p class="uppercase mr-1">Monto PDT</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.amountToPay'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('contract.customer.name')">
													<p class="uppercase mr-1">Nombre</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'contract.customer.name'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>

										<th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
											<div class="font-semibold text-left">
												<button class="flex items-center" @click="handleSort('statusLabel')">
													<p class="uppercase mr-1">Estado</p>
													<icon scale="0.8" name="ri-arrow-up-down-line" v-if="sortBy.field != 'statusLabel'" />
													<icon scale="0.8" name="ri-sort-asc" v-else-if="sortBy.type === ''" />
													<icon scale="0.8" name="ri-sort-desc" v-else-if="sortBy.type === '-'" />
												</button>
											</div>
										</th>
									</tr>
								</thead>
								<!-- Table body -->
								<tbody class="text-sm divide-y divide-gray-200">
									<tr
										v-for="item in items"
										:key="item._id"
										class="even:bg-sky-100 hover:bg-sky-200 cursor-pointer"
										@click.stop="
											() =>
												$router.push({
													name: 'go.dealDashboard',
													query: {
														_id: item._id,
														project: options.project
													}
												})
										"
									>
										<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-medium text-light-blue-500">
												{{ parseCurrency(item.contract.amount || 0, 'en-US', 'USD') }}
											</div>
										</td>
										<td v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-medium text-light-blue-500">
												<span
													class="text-xs mr-1 font-semibold text-white px-1.5 rounded"
													:class="{
														'bg-blue-600': item.contract.rate == 2,
														'bg-green-500': item.contract.rate == 3,
														'bg-green-800': item.contract.rate == 3.1,
														'bg-purple-500': item.contract.rate == 6.1,
														'bg-purple-800': item.contract.rate == 6.2,
														'bg-red-600': item.contract.rate == 6.3,
														'bg-red-800': item.contract.rate == 6.4
													}"
												>
													{{ parseRate(item.contract.rate) }}
												</span>
											</div>
										</td>

										<td v-if="!isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-medium text-light-blue-500">
												{{ item.contract.cups }}
											</div>
										</td>

										<td v-if="isFinance" class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-medium text-red-500">
												{{ parseCurrency(item.contract.amountToPay || 0, 'en-US', 'USD') }}
											</div>
										</td>

										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-medium text-light-blue-500">
												{{ item.contract.customer.name }}
												{{ item.contract.customer.lastname }}
											</div>
										</td>

										<td class="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
											<div class="font-medium text-light-blue-500">
												{{ item.statusLabel }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<!-- Pagination -->
					<div class="mt-8 mb-24" v-if="!loading && items.length">
						<Pagination v-show="total > options.limit" :results="items.length" :skip="options.skip" :limit="options.limit" :total="total" @page="updatePage" />
					</div>

					<!-- No results -->
					<div class="bg-white p-4 rounded-sm border border-gray-200 mt-3" v-if="(!loading && !items.length) || error.message">
						<Banner v-if="!!error.message" type="error" :open="!!error?.message" fixed>
							<p>{{ error.message }}</p>
							<ul v-if="error?.errors">
								<li v-for="(err, i) in error.errors" :key="i">
									<p class="p-2">- {{ err.message }}</p>
								</li>
							</ul>
						</Banner>
						<div class="w-full flex justify-center p-4" v-if="!loading && !items.length">
							<div class="text-center py-6">
								<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-red-300 to-red-100 mb-4">
									<icon name="ri-information-line" scale="2" />
								</div>
								<h2 class="text-2xl text-gray-800 font-bold mb-2">No se han encontrado oportunidades.</h2>
								<div>Explora tu zona para bloquear nuevas oportunidades</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import { ValidationError } from '@/errors/ValidationError'
import { user } from '@/user'
import { Ref, onMounted, reactive, ref, watch } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRoute, useRouter } from 'vue-router'
import Accordion from '../../components/Accordion.vue'
import Banner from '../../components/Banner.vue'
import Pagination from '../../components/Pagination.vue'
import { Project } from '../../interfaces/Project'
import { get } from '../../services/api'
import { parseCurrency } from '../../utils/parseCurrency'
import { parseRate } from '../../utils/parseRate'
import Default from '../Default.vue'
import { Origin } from '../../interfaces/Origin'

const route = useRoute()
const router = useRouter()

const isFinance = import.meta.env.VITE_PRODUCT == 'finance'

const origins: Ref<Origin[]> = ref([])

const options: Record<string, any> = reactive({
	...route.query,
	limit: route.query.limit ? parseInt(<string>route.query.limit) : 20,
	skip: route.query.skip ? parseInt(<string>route.query.skip) : 0,
	sort: route.query.sort || '-createdAt',
	...((route.query.project && { project: route.query.project }) || (user.value?.projects?.length && { project: user.value?.projects[0] })),
	...((route.query.origin && { origin: route.query.origin }) || (origins.value?.length && { origin: origins.value[0]._id })),
	...(route.query['contract.cups'] && { 'contract.cups': route.query['contract.cups'] })
})

const loading = ref(false)
const items = ref([])
const error = ref<ValidationError>(<any>{})
const selectedStatuses = ref([])
const statuses = ref([])
const total = ref(0)
const selectedRates = ref([])
const projects: Ref<Project[]> = ref([])
const sortBy = reactive({ field: (<string>options.sort || '')?.split('-')[0], type: options.sort?.includes('-') ? '-' : '' })
const amountToPayFrom = ref(0)
const amountToPayTo = ref(99999)

const handleSort = (column: string) => {
	if (column != sortBy.field) {
		sortBy.field = column
		sortBy.type = ''
	} else {
		sortBy.type = sortBy.type === '-' ? '' : '-'
	}
	options.sort = `${sortBy.type}${sortBy.field}`
}

const rates = [
	{ _id: 2, label: '2.0TD' },
	{ _id: 3, label: '3.0TD' },
	{ _id: 3.1, label: '3.1TD' },
	{ _id: 6.1, label: '6.1TD' },
	{ _id: 6.2, label: '6.2TD' },
	{ _id: 6.3, label: '6.3TD' },
	{ _id: 6.4, label: '6.4TD' }
]

const onSelectRate = () => {
	const selected = selectedRates.value.map(rate => rate._id)
	if (selected.length) {
		options['contract.rate'] = selected.join(',')
	} else {
		delete options['contract.rate']
	}
}

const onChangeSearchInput = (e: any) => {
	if (!e.target.value) {
		if (isFinance) {
			delete options['contract.customer.identifier']
		} else {
			delete options['contract.cups']
		}
	}
}
const fetchProjects = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		const { data } = await get({ name: 'project', query: { sort: 'name', projection: '_id,name,label' } })
		projects.value = data.filter((project: Project) => user.value?.projects?.includes(project.name)) || []
		options.project = (!!(<string>route.query.project) && projects.value.find(project => route.query.project === project.name))?.name || projects.value[0].name
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const fetchOrigins = async () => {
	try {
		const { data } = await get({ name: 'origin' })
		origins.value = data
		options.origin = (!!(<string>route.query.origin) && origins.value.find(origin => route.query.origin === origin._id))?._id || origins.value[0]._id
	} catch (err) {
		error.value = err
	}
}

const search = async () => {
	loading.value = true
	error.value = <any>{}
	try {
		items.value = []
		const { project, ...restOptions } = options

		let urlPrefix = ''

		if (isFinance) {
			urlPrefix = `contract.amountToPay>=${amountToPayFrom.value}&contract.amountToPay<=${amountToPayTo.value}&`
		}

		const query = { ...restOptions, projection: 'contract.amountToPay,contract.customer,contract.cups,contract.rate,statusLabel' }
		const { data, pagination } = await get({ name: 'me.deal', params: { project }, query: query })

		items.value = data
		total.value = pagination.total
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const searchStatuses = async () => {
	error.value = <any>{}
	loading.value = true
	try {
		statuses.value = []
		const apiQuery = { 'config.isStatus': 'true', 'config.status': null, projection: 'config.status,config.isFinal' }
		const { data } = await get({ name: 'action', query: apiQuery })
		statuses.value = data.map(item => ({ ...item.config.status, isFinal: item.config.isFinal }))

		if (!selectedStatuses.value.length) {
			selectedStatuses.value = statuses.value.filter(status => !status.isFinal)
		}
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const onChangeLimit = () => {
	options.skip = 0
}

watch([options, amountToPayFrom, amountToPayTo], async () => {
	await router.replace({ query: options })
	if (Object.keys(route.query).length) {
		search()
	}
})
watch(
	() => selectedStatuses.value,
	() => {
		const selected = selectedStatuses.value.map(status => status.type)
		if (selected.length) {
			options.status = selected.join(',')
		} else {
			delete options.status
		}
	}
)

onMounted(async () => {
	await router.replace({ query: options })
	await fetchOrigins()
	await fetchProjects()
	selectedRates.value = [...(route.query['contract.rate']?.length ? rates.filter(({ _id }) => route.query['contract.rate']?.includes(_id.toString())) : [])]
	await searchStatuses()
	if (Object.keys(route.query).length) {
		search()
	}
})

const updatePage = (page: Ref) => {
	options.skip = page.value == -1 ? 0 : (page.value - 1) * options.limit
}
</script>

<template>
	<teleport to="body">
		<div class="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="props.contract">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
			<div class="fixed inset-0 z-10 w-full h-screen overflow-y-auto" @click="$emit('abort')">
				<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1">
					<div class="relative transform rounded-sm bg-white text-left shadow-xl transition-all w-full lg:w-3/4 xl:w-5/6 xl:max-w-7xl" @click.stop>
						<div class="bg-white rounded-sm border border-gray-200">
							<div class="flex items-center justify-between gap-4 bg-gray-50 p-3 border-b border-gray-200">
								<h3 class="text-base font-semibold leading-6 text-gray-900">Comparativa</h3>
								<Button color="tertiary" @click="$emit('abort')">
									<icon name="ri-close-line" class="mr-1" />
									Cerrar
								</Button>
							</div>
							<div class="bg-white w-full p-3" v-if="!items.length && !loading">
								<Banner type="warning" :open="true" fixed> <span>No se han encontrado opciones.</span></Banner>
							</div>
							<div class="bg-white w-full">
								<div v-if="loading" class="flex justify-center items-center col-span-full p-4">
									<icon name="ri-loader-4-line" class="mr-1" animation="spin" scale="1.5" />
								</div>
								<Tabs v-if="!loading">
									<Tab name="fixed" title="FIJO">
										<div v-if="items.filter(item => item.priceType === 0)?.length" class="grid xl:grid-cols-2 gap-1">
											<ComparativeCard v-for="item of items.filter(item => item.priceType === 0)" :key="item.brand" :item="item" :cups="contract.cups" :rate="parseRate(contract.rate)" @click="onClick(item)" />
										</div>
										<Banner v-else type="warning" :open="true" fixed> <span>Sin opciones para FIJO.</span></Banner>
									</Tab>
									<Tab name="indexed" title="INDEXADO">
										<div v-if="items.filter(item => item.priceType === 2)?.length" class="grid xl:grid-cols-2 gap-1">
											<ComparativeCard v-for="item of items.filter(item => item.priceType === 2)" :key="item.brand" :item="item" :cups="contract.cups" :rate="parseRate(contract.rate)" @click="onClick(item)"> </ComparativeCard>
										</div>
										<Banner v-else type="warning" :open="true" fixed> <span>Sin opciones para INDEXADO.</span></Banner>
									</Tab>
									<Tab name="flat" title="PLANO">
										<div v-if="items.filter(item => item.priceType === 1)?.length" class="grid xl:grid-cols-2 gap-1">
											<ComparativeCard v-for="item of items.filter(item => item.priceType === 1)" :key="item.brand" :item="item" :cups="contract.cups" :rate="parseRate(contract.rate)" @click="onClick(item)" />
										</div>
										<Banner v-else type="warning" :open="true" fixed> <span>Sin opciones para PLANO.</span></Banner>
									</Tab>
								</Tabs>
							</div>
							<ComparativeInvoice v-if="current" :cups="contract.cups" :rate="parseRate(contract.rate)" :data="current" :power="power" :invoicePeriod="invoicePeriod" @close="current = null" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { compare } from '../../services/ahorreluz'
import { Contract } from '../../interfaces/Contract'
import { parseRate } from '../../utils/parseRate'
import Tabs from '../tabs/Tabs.vue'
import Tab from '../tabs/Tab.vue'
import ComparativeCard from './ComparativeCard.vue'
import ComparativeInvoice from './ComparativeInvoice.vue'
import Banner from '../Banner.vue'
import Button from '../Button.vue'

const loading = ref(true)
const invoicePeriod = ref(null)
const current = ref(null)
const items = ref([])

const props = defineProps<{
	contract: Contract
}>()

const emits = defineEmits(['abort'])

const onClick = item => {
	current.value = item
}

const fetch = async () => {
	if (props.contract?.cups) {
		loading.value = true
		try {
			const data = await compare({
				comparison_type: 'aprox',
				contract_type: '2',
				product_type: '1',
				cups: props.contract.cups,
				salesOrigin: 'appElectryGo',
				comercializadorasComparar: 'ALL'
			})

			if (data?.ofertas?.length) {
				items.value = data.ofertas
				invoicePeriod.value = data.periodoFacturacion
			}
		} catch (error) {
			console.log(error)
		}
		loading.value = false
	}
}

onMounted(() => {
	fetch()
})

watch(
	() => props.contract,
	() => {
		fetch()
	}
)

const power = computed(() => {
	const result = {}
	if (props.contract) {
		for (const key in props.contract) {
			if (key.includes('powerP')) {
				result[key] = props.contract[key]
			}
		}
	}
	return result
})
</script>
